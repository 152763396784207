<template>
  <path
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H14C15.1046 0.5 16 1.39543 16 2.5V14.5C16 15.6046 15.1046 16.5 14 16.5H2C0.895431 16.5 0 15.6046 0 14.5V2.5ZM1.33333 5.5H14.6333V13.8889C14.6333 14.5025 14.1359 15 13.5222 15H2.44445C1.8308 15 1.33333 14.5025 1.33333 13.8889V5.5ZM2.39961 3.7001C2.84144 3.7001 3.19961 3.34193 3.19961 2.9001C3.19961 2.45827 2.84144 2.1001 2.39961 2.1001C1.95778 2.1001 1.59961 2.45827 1.59961 2.9001C1.59961 3.34193 1.95778 3.7001 2.39961 3.7001Z"
  />
</template>
